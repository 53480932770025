var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "content" }, [
      _vm._m(0),
      _c("div", [
        _c(
          "div",
          { staticClass: "top" },
          [
            _c(
              "el-form",
              {
                staticStyle: { width: "300px", "margin-left": "50px" },
                attrs: {
                  "label-position": "right",
                  "label-width": "140px",
                  model: _vm.formInline,
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "规则名称：", prop: "feeName" } },
                  [_c("span", [_vm._v(_vm._s(_vm.formInline.feeName))])]
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "编码：", prop: "feeCode" } },
                  [_c("span", [_vm._v(_vm._s(_vm.formInline.feeCode))])]
                ),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { width: "550px", "word-break": "break-all" },
                    attrs: { label: "描述：", prop: "description" },
                  },
                  [
                    _c("el-input", {
                      attrs: { type: "textarea", readonly: true, rows: 8 },
                      model: {
                        value: _vm.formInline.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "description", $$v)
                        },
                        expression: "formInline.description",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form",
              {
                staticStyle: { width: "300px", "margin-left": "50px" },
                attrs: {
                  "label-position": "right",
                  "label-width": "140px",
                  model: _vm.formInline,
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "所属商户：",
                      prop: "operationManagerName",
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.formInline.operationName))])]
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: { label: "操作人：", prop: "operationManagerName" },
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formInline.operationManagerName)),
                    ]),
                  ]
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "操作时间：", prop: "updatedTime" } },
                  [_c("span", [_vm._v(_vm._s(_vm.formInline.updatedTime))])]
                ),
                _c("el-form-item", { attrs: { label: "免费分钟数：" } }, [
                  _c("span", [_vm._v(_vm._s(_vm.source.freeTime))]),
                ]),
                _c("el-form-item", { attrs: { label: "24小时最高收费：" } }, [
                  _c("span", [_vm._v(_vm._s(_vm.source.limit24hour) + "元")]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "bottom" },
          _vm._l(_vm.source.rules, function (rule) {
            return _c(
              "el-form",
              {
                key: rule.cid,
                staticClass: "b_form",
                attrs: { "label-position": "right", "label-width": "140px" },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "生效日期：", prop: "description" } },
                  [_c("span", [_vm._v(_vm._s(rule.date))])]
                ),
                _c("el-form-item", { attrs: { label: "工作日计费规则：" } }),
                _vm._l(rule.details, function (detail, index) {
                  return _c(
                    "div",
                    { key: detail.cid },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "时段" + (index + 1) + "：" } },
                        [
                          _c("span", [
                            _vm._v(_vm._s(detail.start + "-" + detail.end)),
                          ]),
                        ]
                      ),
                      _vm._l(detail.items, function (item, index) {
                        return _c(
                          "el-form-item",
                          {
                            key: item.cid,
                            attrs: {
                              label: "区间" + (index + 1) + "：",
                              prop: "description",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "计费方式： " +
                                  _vm._s(
                                    item.type == 2
                                      ? "周期"
                                      : item.type == 0
                                      ? "基础时段"
                                      : "次"
                                  )
                              ),
                            ]),
                            _c("span", { staticClass: "feeDis" }, [
                              _vm._v(
                                _vm._s(item.unit) +
                                  "元/" +
                                  _vm._s(
                                    item.type == 1 ? "次" : item.period + "分钟"
                                  )
                              ),
                            ]),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                }),
              ],
              2
            )
          }),
          1
        ),
        _c(
          "div",
          { staticStyle: { "text-align": "center" } },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.$router.go(-1)
                  },
                },
              },
              [_vm._v("返回")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "title" }, [
      _c("div", { staticClass: "title_icon" }),
      _vm._v(" 查看计费规则 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }